import { Audio, Segment, Speaker } from '@/api_client/types/audios'
import { Invoice } from '@/api_client/types/invoices'
import { Purchases } from '@/api_client/types/purchases'
import { deserializeInvoice } from '@/api_client/api/serializers/invoice'

export const deserializePurchase = (data): Purchases => {
  if (data == null) {
    return null
  }

  return {
    id: data.id,
    product: data.product,
    quantity: data.quantity,
    status: data.status,
    invoices: data.invoices.map((item: never) => deserializeInvoice(item)),
    payment_gateway: data.payment_gateway,
    start_date: data.start_date,
    expires_date: data.expires_date,
    cancel_at: data.cancel_at,
    cancel_at_period_end: data.cancel_at_period_end,
    created_at: data.created_at,
    updated_at: data.updated_at,
  }
}
