import { ApiContext } from '../../types'
import { deserializeAudio } from '@/api_client/api/serializers/audio'
import { Audio } from '@/api_client/types/audios'
import { AuthHelper } from '@/api_client'
import generateSignedUrl from '@/api_client/api/generateSignedUrl'
import axios from 'axios'

export default async function addAudio(
  { client, config }: ApiContext,
  language,
  speaker_num,
  email,
  file = null,
  youtube_url = '',
  anonymous_token = false,
  fileName = null,
  length_seconds = null,
  folder_id = null,
): Promise<Audio> {
  try {
    const bearerToken = AuthHelper.getAccessToken(anonymous_token)
    const formData = new FormData()
    if (file != null) {
      // upload to cloud
      try {
        const signedUrlData = await generateSignedUrl(
          { client, config },
          file.name,
          file.type,
        )

        const signed_url = signedUrlData['signed_url']
        await axios.put(signed_url, file, {
          headers: {
            'Content-Type': file.type,
          },
        })
        formData.append(
          'cloud_storage_path',
          signedUrlData['cloud_storage_path'],
        )
        formData.append(
          'cloud_storage_platform',
          signedUrlData['cloud_storage_platform'],
        )
        formData.append(
          'cloud_storage_bucket',
          signedUrlData['cloud_storage_bucket'],
        )
      } catch (ee) {
        console.error(ee)
        formData.append('audio_file', file)
      }
      // const signedUrlData = await generateSignedUrl(
      //   { client, config },
      //   file.name,
      //   file.type,
      // )
      //
      // if (signedUrlData != null) {
      //   const signed_url = signedUrlData['signed_url']
      //   await axios.put(signed_url, file, {
      //     headers: {
      //       'Content-Type': file.type,
      //     },
      //   })
      //   formData.append(
      //     'cloud_storage_path',
      //     signedUrlData['cloud_storage_path'],
      //   )
      //   formData.append(
      //     'cloud_storage_platform',
      //     signedUrlData['cloud_storage_platform'],
      //   )
      //   formData.append(
      //     'cloud_storage_bucket',
      //     signedUrlData['cloud_storage_bucket'],
      //   )
      // } else {
      //   formData.append('audio_file', file)
      // }

      formData.append('name', fileName ?? file.name)
      formData.append('file_type', file.type)
    }
    if (folder_id != null && folder_id != '' && folder_id.toString() != '0') {
      formData.append('folder_id', folder_id.toString())
    }
    // console.log('folder_id.toString()', folder_id.toString())
    // return
    formData.append('youtube_url', youtube_url)
    formData.append('language', language)
    formData.append('receive_email', email)
    formData.append('num_of_speakers', speaker_num)
    formData.append('submit_platform', 'web')
    formData.append('limited_seconds', '0') // keep this '0', server will set this value
    if (length_seconds != null) {
      formData.append('length_seconds', length_seconds)
    }

    // list formData
    const result = await client.audios.create(
      bearerToken.toString(),
      {},
      formData,
    )

    if (result.isSuccess()) {
      const payload = result.success()
      // console.log(payload)
      return deserializeAudio(payload)
    } else {
      throw result.fail()
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}
