export const hi = {
  sign_in: 'साइन इन करें',
  email: 'ईमेल',
  password: 'पासवर्ड',
  i_agree_to_toc: 'मैं नियम और शर्तों से सहमत हूं।',
  terms_and_conditions: 'नियम और शर्तें',
  privacy_policy: 'गोपनीयता नीति',
  already_have_an_account: 'पहले से एक खाता है?',
  sign_up: 'साइन अप करें',
  dont_have_an_account: 'क्या आपका कोई खाता नहीं है?',
  forgot_password: 'पासवर्ड भूल गए?',
  email_already_in_use: 'ईमेल पहले से उपयोग में है',
  weak_password: 'कमजोर पासवर्ड',
  too_many_attempts: 'बहुत ज्यादा प्रयास',
  invalid_email_or_password: 'अवैध ईमेल या पासवर्ड',
  user_already_registered: 'उपयोगकर्ता पहले से पंजीकृत है',
  unexpected_error: 'अप्रत्याशित त्रुटि',
  audios: 'ऑडियो',
  name: 'नाम',
  auto: 'ऑटो',
  auto_detect: 'स्वतः पहचान',
  speaker_num: 'स्पीकर की संख्या',
  speaker_num_reason:
    'अगर आप हमें स्पीकर की संख्या बताएंगे, तो हम बेहतर प्रतिलेखन परिणाम प्रदान करेंगे।',
  audio_language: 'ऑडियो भाषा',
  your_email: 'आपका ईमेल',
  email_result: 'हम आपको प्रतिलेखन परिणाम के साथ एक ईमेल भेजेंगे।',
  provide_language:
    'अगर आप हमें ऑडियो की भाषा बताएंगे, तो हम बेहतर प्रतिलेखन परिणाम प्रदान करेंगे।',
  upload_audio: 'ऑडियो अपलोड करें',
  upload_file: 'फ़ाइल अपलोड करें',
  process_audio: 'आपकी फ़ाइल को संसाधित कर रहा है',
  process_audio_des:
    'कृपया प्रतीक्षा करें जब तक आपका ऑडियो प्रतिलेखित हो रहा हो। इसमें कुछ मिनट लग सकते हैं।',
  uploading: 'अपलोड हो रहा है',
  pending: 'लंबित',
  uploaded: 'अपलोड हो गया',
  failed: 'विफल',
  status: 'स्थिति',
  language: 'भाषा',
  speakers: 'वक्ताओं की संख्या',
  duration: 'अवधि',
  done: 'समाप्त',
  close: 'बंद करें',
  queuing: 'कतार में',
  created_at: 'बनाया गया',
  records: 'रिकॉर्ड्स',
  transcribe: 'प्रतिलिपि करें',
  delete: 'हटाएं',
  processing: 'संसाधन कर रहा है',
  from_youtube: 'यूट्यूब से',
  upload_files: 'फ़ाइलें अपलोड करें',
  place_youtube_link_here: 'यहाँ यूट्यूब लिंक रखें',
  submit: 'जमा करें',
  email_notification:
    'ऑडियो फ़ाइल की लंबाई और जटिलता के आधार पर बदलाव का समय होता है। आम तौर पर, आप कुछ घंटों में अपना प्रतिलेखन प्राप्त करने की उम्मीद कर सकते हैं। जैसे ही यह तैयार होगा, हम आपको ईमेल द्वारा सूचित करेंगे।',
  filters: 'फ़िल्टर',
  filter: 'फ़िल्टर करें',
  replace: 'बदलें',
  share_and_export: 'साझा करें और निर्यात करें',
  replace_all: 'सभी बदलें',
  next: 'अगला',
  previous: 'पिछला',
  find: 'खोजें',
  replace_with: 'के साथ बदलें',
  x_of_x: '{current} में से {total}',
  scroll_lock: 'स्क्रॉल लॉक',
  tags: 'टैग',
  bookmark: 'बुकमार्क',
  audio: 'ऑडियो',
  cancel: 'रद्द करें',
  export: 'निर्यात करें',
  export_format: 'निर्यात प्रारूप',
  copy_text: 'पाठ कॉपी करें',
  monologue: 'मोनोलॉग',
  include_timestamps: 'टाइमस्टैम्प्स शामिल करें',
  include_speaker_names: 'स्पीकर के नाम शामिल करें',
  combine_same_speaker: 'समान स्पीकर सेगमेंट को संयोजित करें',
  export_mode: 'निर्यात मोड',
  no_preview: 'कोई पूर्वावलोकन नहीं',
  copy: 'कॉपी करें',
  copied: 'कॉपी हो गया',
  unlock_pro_feature:
    "इस फीचर को अनलॉक करने के लिए, 'प्रो' सब्सक्रिप्शन प्लान को अपग्रेड करें।",
  pro_features: 'प्रो विशेषताएँ',
  pro_minutes: '1800 मिनट / महीना',
  pro_interact_with_audio: 'ऑडियो के साथ इंटरैक्ट करें',
  pro_interact_with_audio_des: 'अपने ऑडियो से प्रश्न पूछें',
  pro_summary: 'एआई सारांश',
  pro_summary_des: 'अपने ऑडियो का सारांश दें',
  pro_priority: 'प्राथमिकता कतार',
  pro_priority_des: 'प्राथमिकता के साथ ऑडियो संसाधित करें',
  pro_multi_speakers: 'मल्टी-स्पीकर्स पहचान',
  pro_multi_speakers_des: 'विभिन्न वक्ताओं को सही ढंग से पहचानें और लेबल करें',
  redirect_to_stripe:
    'भुगतान पूरा करने के लिए आपको स्ट्राइप पर रीडायरेक्ट किया जाएगा।',
  x_per_month: '{x} / महीना',
  x_per_year: '{x} / वर्ष',
  trash: 'कचरा',
  dashboard: 'डैशबोर्ड',
  logout: 'लॉग आउट',
  are_you_sure_to_delete: 'क्या आप चयनित रिकॉर्ड को हटाना सुनिश्चित हैं?',
  restore: 'पुनर्स्थापित करें',
  are_you_sure_to_restore:
    'क्या आप चयनित रिकॉर्ड को पुनर्स्थापित करना सुनिश्चित हैं?',
  deleted_at: 'हटाया गया',
  free_member_limited_view:
    'फ्री सदस्य केवल प्रतिलेखन परिणाम के पहले {x} मिनट तक पहुंच सकता है।',
  hidden_segment: '{y} मिनट के बाद {x} सेगमेंट छुपा दिए गए हैं।',
  settings: 'सेटिंग्स',
  subscription: 'सदस्यता',
  display_language: 'उपयोगकर्ता इंटरफ़ेस भाषा',
  default_transcription_language: 'डिफ़ॉल्ट प्रतिलेखन भाषा',
  contact_us: 'हमसे संपर्क करें',
  security: 'सुरक्षा',
  about_us: 'हमारे बारे में',
  download_app: 'ऐप डाउनलोड करें',
  download: 'डाउनलोड करें',
  app_store: 'ऐप स्टोर',
  play_store: 'प्ले स्टोर',
  upload_voice: 'आवाज अपलोड करें',
  no_audio_found: 'कोई ऑडियो नहीं मिला',
  please_input_valid_email_first: 'कृपया पहले मान्य ईमेल दर्ज करें',
  handling_audio:
    'हम आपके ऑडियो को प्रतिलेखित कर रहे हैं। कृपया एक क्षण प्रतीक्षा करें।',
  welcome_to_leave:
    'आपका स्वागत है इस पृष्ठ को छोड़ने के लिए, क्योंकि हम आपको प्रतिलेखन परिणाम के साथ एक ईमेल भेजेंगे।',
  send_your_result: 'हम आपको प्रतिलेखन परिणाम के साथ एक ईमेल भेजेंगे।',
  transcribe_audio_thank:
    'हमारी प्रतिलेखन सेवा का उपयोग करने के लिए धन्यवाद! हम आपके ऑडियो को प्रतिलेखित करने के लिए कड़ी मेहनत कर रहे हैं और आपके धैर्य की सराहना करते हैं।',
  transcribe_audio_no_stay:
    'आपका प्रतिलेखन थोड़ी देर में हो सकता है, लेकिन चिंता न करें, आपको इस पृष्ठ पर रहने की आवश्यकता नहीं है। जैसे ही वे तैयार होंगे हम आपको ईमेल के माध्यम से परिणाम भेज देंगे।',
  date: 'तारीख',
  sign_up_to_access_more_functions:
    'अधिक कार्यों तक पहुंचने के लिए साइन अप करें',
  you_can_use_functions:
    'आप लॉगिन के बाद निम्नलिखित कार्यों का उपयोग कर सकते हैं',
  know_more: 'और जानें',
  modify_transcription: 'पाठ प्रतिलेखन संशोधित करें',
  play_audio: 'ऑडियो चलाएं',
  content_filters: 'सामग्री फ़िल्टर',
  export_diff_type: 'विभिन्न प्रकार की फाइलें निर्यात करें',
  screenshots: 'स्क्रीनशॉट्स',
  free_trial: 'फ्री ट्रायल',
  pricing: 'मूल्य निर्धारण',
  faq: 'सामान्य प्रश्न',
  contact: 'संपर्क करें',
  login: 'लॉगिन',
  register: 'पंजीकरण',
  please_login_before_purchase: 'कृपया खरीदारी से पहले लॉगिन करें',
  no_records_found: 'कोई रिकॉर्ड नहीं मिला',
  x_minutes: '{x} मिनट',
  confirm: 'पुष्टि करें',
  notification_mobile_app: 'मोबाइल ऐप अधिसूचना',
  notification_mobile_app_des:
    'जब प्रतिलेखन परिणाम तैयार हो जाएगा तो मोबाइल ऐप अधिसूचना प्राप्त करें',
  notification_email: 'ईमेल अधिसूचना',
  notification_email_des:
    'जब प्रतिलेखन परिणाम तैयार हो जाएगा तो ईमेल अधिसूचना प्राप्त करें',
  video: 'वीडियो',
  or: 'या',
  sign_in_with_google: 'Google के साथ साइन इन करें',
  sign_in_with_apple: 'Apple के साथ साइन इन करें',
  login_method: 'लॉगिन विधि',
  delete_account: 'खाता हटाएं',
  delete_account_confirm_3_time: 'पुष्टि करने के लिए हटाने बटन को 3 बार दबाएं',
  popup_blocked: 'पॉपअप अवरुद्ध',
  account_info: 'खाता जानकारी',
  redeem: 'रिडीम',
  redeem_code: 'रिडीम कोड',
  summary_des1:
    'सारांश सेवा प्रतिलेखन परिणाम का एक सारांश प्रदान करती है। यह प्रतिलेखन परिणाम को कुछ वाक्यों में सारांशित कर सकती है।',
  summary_des2: 'आपकापुराना सारांश नए सारांश द्वारा प्रतिस्थापित किया जाएगा।',
  consume_transcription_quota: 'प्रतिलेखन कोटा का उपभोग करें',
  summary: 'सारांश',
  query: 'प्रश्न',
  mins_per_query: '{x} मिनट प्रति प्रश्न',
  free_user_service_once:
    'मुक्त उपयोगकर्ता केवल इस सेवा का एक बार उपयोग कर सकते हैं।',
  free_user_summary:
    'मुक्त उपयोगकर्ता केवल इस सेवा का एक बार उपयोग कर सकते हैं। कृपया अधिक ऑडियो का सारांश करने के लिए प्रो में अपग्रेड करें।',
  free_user_query:
    'मुक्त उपयोगकर्ता केवल इस सेवा का एक बार उपयोग कर सकते हैं। कृपया अधिक प्रश्न पूछने के लिए प्रो में अपग्रेड करें।',
  information_not_found: 'जानकारी नहीं मिली',
  permission_deny: 'अनुमति अस्वीकृत',
  please_login_to_check_the_result: 'कृपया परिणाम की जांच के लिए लॉगिन करें',
  speaker_: 'वक्ता {x}',
  audio_length: 'ऑडियो लंबाई',
  delete_permanently: 'स्थायी रूप से हटाएं',
  are_you_sure_to_delete_permanently:
    'क्या आप स्थायी रूप से हटाने के लिए सुनिश्चित हैं?',
  delete_audios_move_to_bin:
    'हटाए गए रिकॉर्ड्स को कचरा में स्थानांतरित कर दिया जाएगा। कचरा में सभी रिकॉर्ड्स 30 दिनों के बाद हटा दिए जाएंगे।',
  payment_id: 'भुगतान आईडी',
  current_plan: 'वर्तमान योजना',
  plan_name: 'योजना का नाम',
  expire_date: 'समाप्ति तिथि',
  subscription_method: 'सदस्यता विधि',
  payment_method: 'भुगतान विधि',
  payment_history: 'भुगतान इतिहास',
  amount: 'राशि',
  invoice: 'चालान',
  receipt: 'रसीद',
  link: 'लिंक',
  edit: 'संपादित करें',
  back: 'वापस',
  return: 'वापसी',
  cancel_subscription: 'सदस्यता रद्द करें',
  cancel_subscription_google_play:
    'अपने एंड्रॉइड डिवाइस पर > गूगल प्ले स्टोर पर जाएं > खाता > भुगतान और सदस्यता > सदस्यता > साउंडटाइप एआई > सदस्यता रद्द करें',
  cancel_subscription_apple_store:
    'अपने iOS डिवाइस पर > सेटिंग्स पर जाएं > [आपका नाम] > सदस्यता > साउंडटाइप एआई > सदस्यता रद्द करें',
  delete_account_des: 'खाता हटाने से आपके सभी डेटा स्थायी रूप से हट जाएंगे।',
  delete_account_subscription:
    'अगर आपके पास कोई सदस्यता है, तो कृपया पहले अपनी सदस्यता रद्द करें।',
  keep_subscription: 'सदस्यता बनाए रखें',
  cancel_subscription_des:
    'आपकी सदस्यता वर्तमान बिलिंग चक्र ({x}) के अंत में रद्द कर दी जाएगी। इसके बाद, आपको मुफ्त योजना में डाउनग्रेड कर दिया जाएगा और आप प्रो योजना की सुविधाओं तक पहुंचने में असमर्थ होंगे:',
  cancel_subscription_feature1: 'रिकॉर्डिंग्स',
  cancel_subscription_feature1_des:
    'आप केवल अपनी रिकॉर्डिंग्स के पहले {x} मिनट देख सकेंगे।',
  cancel_subscription_feature2: 'प्रतिलेखन समय',
  cancel_subscription_feature2_des:
    'आप केवल प्रति माह {x} मिनट प्रतिलेखन कर सकेंगे।',
  cancel_subscription_count: 'कृपया पुष्टि के लिए बटन {x} बार दबाएं',
  next_payment_date: 'अगली भुगतान तिथि',
  basic_plan: 'मूल योजना',
  resume_subscription: 'सदस्यता पुनः प्रारंभ करें',
  active: 'सक्रिय',
  expired: 'समाप्त',
  auto_renewal_clause: 'स्वचालित नवीनीकरण खंड',
  auto_renewal_payment_web:
    'आपकी सदस्यता स्वचालित रूप से नवीनीकृत हो जाएगी जब तक कि आप इसे वर्तमान अवधि के अंत से कम से कम 24 घंटे पहले रद्द नहीं करते। वर्तमान अवधि के अंत से 24 घंटे पहले आपके खाते से नवीनीकरण के लिए शुल्क लिया जाएगा। आप सेटिंग्स > सदस्यता में जाकर अपनी सदस्यताओं का प्रबंधन और रद्द कर सकते हैं।',
  auto_renewal_terms_and_conditions:
    'सदस्यता लेकर, आप हमारी नियम और शर्तों और गोपनीयता नीति से सहमत होते हैं।',
  payment: 'भुगतान',
  monthly: 'मासिक',
  annual: 'वार्षिक',
  minutes_per_month: '{x} मिनट/माह',
  buy_now: 'अभी खरीदें',
  yearly: 'वार्षिक',
  yearly_save: 'बचत करें {x}%',
  pro_plan: 'प्रो योजना',
  business_plan: 'बिजनेस योजना',
  enterprise_plan: 'उद्यम योजना',
  everything_in_basic: 'बेसिक में सब कुछ, और अधिक',
  everything_in_pro: 'प्रो में सब कुछ, और अधिक',
  everything_in_business: 'बिजनेस में सब कुछ, और अधिक',
  ai_chat_x: 'एआई चैट x {x}',
  ai_summary_x: 'एआई सारांश x {x}',
  max_x_minutes_per_transcription: 'प्रति प्रतिलेखन {x} मिनट अधिकतम',
  basic_des2: 'वक्ता पहचान',
  basic_des3: 'ऑडियो/वीडियो फ़ाइल प्रतिलेखन',
  basic_des4: 'प्रतिलेख समीक्षा और संपादित करें',
  pro_des2: 'प्राथमिकता कतार',
  pro_des3: 'SRT प्रारूप में निर्यात',
  pro_des4: 'असीमित एआई सारांश',
  business_des1: 'उपयोग विश्लेषण रिपोर्ट',
  business_des2: 'टीम के सदस्यों के साथ सहयोगात्मक कार्यक्षेत्र',
  business_des3: 'अपनी टीम के साथ प्रतिलेख साझा करें और संपादित करें।',
  business_des4: 'शेयरिंग अनुमतियों का नियंत्रण',
  coming_soon: 'जल्द आ रहा है',
  get_started: 'शुरू करें',
  bill_annually_x: 'प्रतिवर्ष {x} का बिल',
  no_credit_card_required: 'कोई क्रेडिट कार्ड की आवश्यकता नहीं है',
  save_x: 'बचत करें {x}%',
  price_per_month: ' / महीना',
  not_available: 'उपलब्ध नहीं है',
  pricing_title: 'मूल्य निर्धारण',
  upgrade: 'अपग्रेड करें',
  ask_question_to_your_audio: 'अपने ऑडियो से प्रश्न पूछें',
  monthly_minutes: 'मासिक मिनट्स',
  payment_interval: 'भुगतान अंतराल',
  annually: 'वार्षिक',
  billing: 'बिलिंग',
  subscription_plan: 'योजना',
  please_cancel_your_current_subscription_first:
    'कृपया पहले अपनी वर्तमान सदस्यता रद्द करें',
  please_cancel_your_current_subscription_first_des:
    'अपनी सदस्यता योजना को बदलने के लिए, आपको पहले अपनी वर्तमान सदस्यता रद्द करनी होगी।',
  cancel_subscription_instruction:
    'सेटिंग्स > बिलिंग > सदस्यता रद्द करें पर जाएं',
  website_version: 'वेबसाइट संस्करण',
  change_to_annual_plan: 'वार्षिक योजना में परिवर्तन करें',
  update_annual_mobile_platform_reminder: 'अनुस्मारक',
  update_annual_mobile_platform_reminder_des:
    'कृपया सूचित करें कि वार्षिक योजना में परिवर्तन करने से पहले आपको {x} पर अपनी वर्तमान सदस्यता रद्द करनी होगी। अन्यथा, आपसे दो बार शुल्क लिया जाएगा।',
  i_understand: 'मैं समझता हूं',
  no_credit: 'पर्याप्त मिनट्स नहीं हैं',
  re_submit: 'फिर से जमा करें',
  loading_audio: 'ऑडियो लोड हो रहा है',
  your_password: 'आपका पासवर्ड',
  reset_password_email_sent:
    'आपके ईमेल पते पर एक ईमेल भेजा गया है। कृपया अपना पासवर्ड रीसेट करने के लिए अपने इनबॉक्स की जांच करें।',
  home: 'मुख्य पृष्ठ',
  title_a_z: 'शीर्षक A-Z',
  title_z_a: 'शीर्षक Z-A',
  older_first: 'पुराना पहले',
  newest_first: 'नया पहले',
  table_item_count: '{z} वस्तुओं में से {x} - {y}',
  starred: 'चिह्नित',
  search: 'खोजें',
  create_folder: 'फोल्डर बनाएं',
  folder_name: 'फोल्डर का नाम',
  create: 'बनाएं',
  export_preview: 'निर्यात पूर्वावलोकन',
  combine_segments: 'सेगमेंट्स को संयोजित करें',
  export_audio_only:
    'आप केवल तभी ऑडियो निर्यात कर सकते हैं जब ऑडियो प्रतिलेखन हो रहा हो।',
  transcribe_remaining_x_minutes: 'शेष {x} मिनट का प्रतिलेखन करें',
  x_minutes_remain_be_transcribed: '{x} मिनट प्रतिलेखन होना बाकी है',
  free_member_limited_transcribe_view:
    'केवल पहले {x} मिनट प्रतिलेखित किए जाएंगे और फ्री सदस्य के लिए देखे जा सकते हैं',
  upgrade_plan_to_transcribe:
    'पूरे ऑडियो को प्रतिलेखित करने के लिए अपनी योजना को अपग्रेड करें',
  only_paid_user_can_use:
    'केवल भुगतान किए गए उपयोगकर्ता शेष मिनट्स को प्रतिलेखित कर सकते हैं',
  converting_to_audio: 'ऑडियो में परिवर्तित हो रहा है',
}
