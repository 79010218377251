export const th = {
  sign_in: 'Đăng nhập',
  email: 'Email',
  password: 'Mật khẩu',
  i_agree_to_toc: 'Tôi đồng ý với các điều khoản và điều kiện.',
  terms_and_conditions: 'Điều khoản và điều kiện',
  privacy_policy: 'Chính sách bảo mật',
  already_have_an_account: 'Đã có tài khoản?',
  sign_up: 'Đăng ký',
  dont_have_an_account: 'Chưa có tài khoản?',
  forgot_password: 'Quên mật khẩu?',
  email_already_in_use: 'Email đã được sử dụng',
  weak_password: 'Mật khẩu yếu',
  too_many_attempts: 'Quá nhiều lần thử',
  invalid_email_or_password: 'Email hoặc mật khẩu không hợp lệ',
  user_already_registered: 'Người dùng đã đăng ký',
  unexpected_error: 'Lỗi không mong đợi',
  audios: 'Âm thanh',
  name: 'Tên',
  auto: 'Tự động',
  auto_detect: 'Tự động phát hiện',
  speaker_num: 'Số lượng người nói',
  speaker_num_reason:
    'Nếu bạn cho chúng tôi biết số lượng người nói, chúng tôi sẽ cung cấp kết quả chép lời tốt hơn.',
  audio_language: 'Ngôn ngữ âm thanh',
  your_email: 'Email của bạn',
  email_result: 'Chúng tôi sẽ gửi kết quả chép lời qua email cho bạn.',
  provide_language:
    'Nếu bạn cho chúng tôi biết ngôn ngữ âm thanh, chúng tôi sẽ cung cấp kết quả chép lời tốt hơn.',
  upload_audio: 'Tải lên âm thanh',
  upload_file: 'Tải lên tập tin',
  process_audio: 'Đang xử lý tập tin của bạn',
  process_audio_des:
    'Vui lòng đợi trong khi âm thanh của bạn được chép lời. Quá trình này có thể mất vài phút.',
  uploading: 'Đang tải lên',
  pending: 'Đang chờ xử lý',
  uploaded: 'Đã tải lên',
  failed: 'Thất bại',
  status: 'Trạng thái',
  language: 'Ngôn ngữ',
  speakers: 'Người nói',
  duration: 'Thời lượng',
  done: 'Hoàn tất',
  close: 'Đóng',
  queuing: 'Đang xếp hàng',
  created_at: 'Được tạo vào',
  records: 'Hồ sơ',
  transcribe: 'Chép lời',
  delete: 'Xóa',
  processing: 'Đang xử lý',
  from_youtube: 'Từ Youtube',
  upload_files: 'Tải lên tập tin',
  place_youtube_link_here: 'Đặt liên kết Youtube ở đây',
  submit: 'Gửi đi',
  email_notification:
    'Thời gian xử lý phụ thuộc vào độ dài và độ phức tạp của tập tin âm thanh. Thông thường, bạn có thể nhận được kết quả chép lời trong vài giờ. Chúng tôi sẽ thông báo qua email ngay khi hoàn thành.',
  filters: 'Bộ lọc',
  filter: 'Lọc',
  replace: 'Thay thế',
  share_and_export: 'Chia sẻ và Xuất khẩu',
  replace_all: 'Thay thế tất cả',
  next: 'Tiếp theo',
  previous: 'Trước',
  find: 'Tìm kiếm',
  replace_with: 'Thay thế bằng',
  x_of_x: '{current} trong {total}',
  scroll_lock: 'Khóa cuộn',
  tags: 'Thẻ',
  bookmark: 'Đánh dấu',
  audio: 'Âm thanh',
  cancel: 'Hủy',
  export: 'Xuất khẩu',
  export_format: 'Định dạng xuất khẩu',
  copy_text: 'Sao chép văn bản',
  monologue: 'Độc thoại',
  include_timestamps: 'Bao gồm dấu thời gian',
  include_speaker_names: 'Bao gồm tên người nói',
  combine_same_speaker: 'Kết hợp các phân đoạn của cùng người nói',
  export_mode: 'Chế độ xuất khẩu',
  no_preview: 'Không có bản xem trước',
  copy: 'Sao chép',
  copied: 'Đã sao chép',
  unlock_pro_feature:
    "Để mở khóa tính năng này, hãy nâng cấp lên gói đăng ký 'Pro'.",
  pro_features: 'Tính năng Pro',
  pro_minutes: '1800 phút / tháng',
  pro_interact_with_audio: 'Tương tác với âm thanh',
  pro_interact_with_audio_des: 'Đặt câu hỏi cho âm thanh của bạn',
  pro_summary: 'Tóm tắt AI',
  pro_summary_des: 'Tóm tắt âm thanh của bạn',
  pro_priority: 'Hàng đợi ưu tiên',
  pro_priority_des: 'Xử lý âm thanh với ưu tiên',
  pro_multi_speakers: 'Nhận diện nhiều người nói',
  pro_multi_speakers_des:
    'Phát hiện và gắn nhãn chính xác các người nói khác nhau',
  redirect_to_stripe:
    'Bạn sẽ được chuyển hướng đến Stripe để hoàn tất thanh toán.',
  x_per_month: '{x} / Tháng',
  x_per_year: '{x} / Năm',
  trash: 'Thùng rác',
  dashboard: 'Bảng điều khiển',
  logout: 'Đăng xuất',
  are_you_sure_to_delete: 'Bạn có chắc chắn muốn xóa các bản ghi đã chọn?',
  restore: 'Khôi phục',
  are_you_sure_to_restore:
    'Bạn có chắc chắn muốn khôi phục các bản ghi đã chọn?',
  deleted_at: 'Đã xóa vào',
  free_member_limited_view:
    'Thành viên miễn phí chỉ có thể truy cập vào {x} phút đầu tiên của kết quả chép lời.',
  hidden_segment: '{x} đoạn bị ẩn sau {y} phút.',
  settings: 'Cài đặt',
  subscription: 'Đăng ký',
  display_language: 'Ngôn ngữ giao diện người dùng',
  default_transcription_language: 'Ngôn ngữ chép lời mặc định',
  contact_us: 'Liên hệ chúng tôi',
  security: 'Bảo mật',
  about_us: 'Về chúng tôi',
  download_app: 'Tải xuống ứng dụng',
  download: 'Tải xuống',
  app_store: 'App Store',
  play_store: 'Play Store',
  upload_voice: 'Tải lên giọng nói',
  no_audio_found: 'Không tìm thấy âm thanh',
  please_input_valid_email_first: 'Vui lòng nhập email hợp lệ trước',
  handling_audio:
    'Chúng tôi đang chép lời âm thanh của bạn. Vui lòng đợi một chút.',
  welcome_to_leave:
    'Bạn có thể rời khỏi trang này vì chúng tôi sẽ gửi kết quả chép lời qua email cho bạn.',
  send_your_result: 'Chúng tôi sẽ gửi kết quả chép lời qua email cho bạn.',
  transcribe_audio_thank:
    'Cảm ơn bạn đã sử dụng dịch vụ chép lời của chúng tôi! Chúng tôi đang nỗ lực chép lời âm thanh của bạn và rất cảm ơn sự kiên nhẫn của bạn.',
  transcribe_audio_no_stay:
    'Việc chép lời có thể mất một thời gian, nhưng đừng lo lắng, bạn không cần phải ở lại trang này. Chúng tôi sẽ gửi email kết quả cho bạn ngay khi hoàn thành.',
  date: 'Ngày',
  sign_up_to_access_more_functions: 'Đăng ký để truy cập thêm các chức năng',
  you_can_use_functions: 'Bạn có thể sử dụng các chức năng sau khi đăng nhập',
  know_more: 'Tìm hiểu thêm',
  modify_transcription: 'Chỉnh sửa văn bản chép lời',
  play_audio: 'Phát âm thanh',
  content_filters: 'Bộ lọc nội dung',
  export_diff_type: 'Xuất khẩu các loại tập tin khác nhau',
  screenshots: 'Ảnh chụp màn hình',
  free_trial: 'Dùng thử miễn phí',
  pricing: 'Giá cả',
  faq: 'Câu hỏi thường gặp',
  contact: 'Liên hệ',
  login: 'Đăng nhập',
  register: 'Đăng ký',
  please_login_before_purchase: 'Vui lòng đăng nhập trước khi mua',
  no_records_found: 'Không tìm thấy bản ghi',
  x_minutes: '{x} Phút',
  confirm: 'Xác nhận',
  notification_mobile_app: 'Thông báo ứng dụng di động',
  notification_mobile_app_des:
    'Nhận thông báo từ ứng dụng di động khi kết quả chép lời đã sẵn sàng',
  notification_email: 'Thông báo Email',
  notification_email_des:
    'Nhận thông báo qua email khi kết quả chép lời đã sẵn sàng',
  video: 'Video',
  or: 'Hoặc',
  sign_in_with_google: 'Đăng nhập với Google',
  sign_in_with_apple: 'Đăng nhập với Apple',
  login_method: 'Phương thức đăng nhập',
  delete_account: 'Xóa tài khoản',
  delete_account_confirm_3_time: 'Nhấn nút xóa 3 lần để xác nhận',
  popup_blocked: 'Cửa sổ bật lên bị chặn',
  account_info: 'Thông tin tài khoản',
  redeem: 'Đổi mã',
  redeem_code: 'Mã đổi',
  summary_des1:
    'Dịch vụ tóm tắt cung cấp bản tóm tắt của kết quả chép lời. Nó có thể tóm tắt kết quả chép lời thành vài câu.',
  summary_des2: 'Bản tóm tắt cũ của bạn sẽ bị thay thế bởi bản tóm tắt mới.',
  consume_transcription_quota: 'Tiêu tốn hạn mức chép lời',
  summary: 'Tóm tắt',
  query: 'Truy vấn',
  mins_per_query: '{x} phút cho mỗi truy vấn',
  free_user_service_once:
    'Người dùng miễn phí chỉ có thể sử dụng dịch vụ này một lần.',
  free_user_summary:
    'Người dùng miễn phí chỉ có thể sử dụng dịch vụ này một lần. Vui lòng nâng cấp lên Pro để tóm tắt thêm âm thanh.',
  free_user_query:
    'Người dùng miễn phí chỉ có thể sử dụng dịch vụ này một lần. Vui lòng nâng cấp lên Pro để hỏi thêm truy vấn.',
  information_not_found: 'Không tìm thấy thông tin',
  permission_deny: 'Từ chối quyền',
  please_login_to_check_the_result: 'Vui lòng đăng nhập để kiểm tra kết quả',
  speaker_: 'Người nói {x}',
  audio_length: 'Độ dài âm thanh',
  delete_permanently: 'Xóa vĩnh viễn',
  are_you_sure_to_delete_permanently: 'Bạn có chắc chắn muốn xóa vĩnh viễn?',
  delete_audios_move_to_bin:
    'Các bản ghi đã xóa sẽ được di chuyển vào thùng rác. Tất cả các bản ghi trong thùng rác sẽ bị xóa sau 30 ngày.',
  payment_id: 'Mã thanh toán',
  current_plan: 'Gói hiện tại',
  plan_name: 'Tên gói',
  expire_date: 'Ngày hết hạn',
  subscription_method: 'Phương thức đăng ký',
  payment_method: 'Phương thức thanh toán',
  payment_history: 'Lịch sử thanh toán',
  amount: 'Số tiền',
  invoice: 'Hóa đơn',
  receipt: 'Biên nhận',
  link: 'Liên kết',
  edit: 'Chỉnh sửa',
  back: 'Quay lại',
  return: 'Trở lại',
  cancel_subscription: 'Hủy đăng ký',
  cancel_subscription_google_play:
    'Trên thiết bị Android của bạn > Truy cập Google Play Store > Tài khoản > Thanh toán và đăng ký > Đăng ký > SoundType AI > Hủy đăng ký',
  cancel_subscription_apple_store:
    'Trên thiết bị iOS của bạn > Vào Cài đặt > [tên của bạn] > Đăng ký > SoundType AI > Hủy đăng ký',
  delete_account_des: 'Xóa tài khoản sẽ xóa tất cả dữ liệu của bạn vĩnh viễn.',
  delete_account_subscription:
    'Nếu bạn có đăng ký, vui lòng hủy đăng ký trước.',
  keep_subscription: 'Giữ đăng ký',
  cancel_subscription_des:
    'Đăng ký của bạn sẽ bị hủy vào cuối chu kỳ thanh toán hiện tại ({x}). Sau ngày đó, bạn sẽ bị hạ cấp xuống gói miễn phí và bạn sẽ không thể truy cập các tính năng của gói Pro:',
  cancel_subscription_feature1: 'Bản ghi âm',
  cancel_subscription_feature1_des:
    'Bạn chỉ có thể xem {x} phút đầu tiên của bản ghi âm của mình.',
  cancel_subscription_feature2: 'Thời gian chép lời',
  cancel_subscription_feature2_des:
    'Bạn chỉ có thể chép lời {x} phút mỗi tháng.',
  cancel_subscription_count: 'Vui lòng nhấn nút {x} lần để xác nhận',
  next_payment_date: 'Ngày thanh toán tiếp theo',
  basic_plan: 'Gói Cơ bản',
  resume_subscription: 'Tiếp tục đăng ký',
  active: 'Đang hoạt động',
  expired: 'Đã hết hạn',
  auto_renewal_clause: 'Điều khoản tự động gia hạn',
  auto_renewal_payment_web:
    'Đăng ký của bạn sẽ tự động gia hạn trừ khi bạn hủy ít nhất 24 giờ trước khi kết thúc chu kỳ hiện tại. Tài khoản của bạn sẽ bị tính phí gia hạn trong vòng 24 giờ trước khi kết thúc chu kỳ hiện tại. Bạn có thể quản lý và hủy đăng ký của mình bằng cách vào Cài đặt > Đăng ký.',
  auto_renewal_terms_and_conditions:
    'Bằng cách đăng ký, bạn đồng ý với các Điều khoản và Điều kiện và Chính sách Bảo mật của chúng tôi.',
  payment: 'Thanh toán',
  monthly: 'Hàng tháng',
  annual: 'Hàng năm',
  minutes_per_month: '{x} phút / tháng',
  buy_now: 'Mua ngay',
  yearly: 'Hàng năm',
  yearly_save: 'Tiết kiệm đến {x}%',
  pro_plan: 'Pro',
  business_plan: 'Doanh nghiệp',
  enterprise_plan: 'Doanh nghiệp lớn',
  everything_in_basic: 'Mọi thứ trong gói Cơ bản, và thêm',
  everything_in_pro: 'Mọi thứ trong gói Pro, và thêm',
  everything_in_business: 'Mọi thứ trong gói Doanh nghiệp, và thêm',
  ai_chat_x: 'AI Chat x {x}',
  ai_summary_x: 'Tóm tắt AI x {x}',
  max_x_minutes_per_transcription: 'Tối đa {x} phút mỗi lần chép lời',
  basic_des2: 'Nhận diện người nói',
  basic_des3: 'Chép lời tập tin âm thanh/video',
  basic_des4: 'Xem xét & chỉnh sửa văn bản chép lời',
  pro_des2: 'Hàng đợi ưu tiên',
  pro_des3: 'Xuất khẩu ở định dạng SRT',
  pro_des4: 'Tóm tắt AI không giới hạn',
  business_des1: 'Báo cáo phân tích sử dụng',
  business_des2: 'Không gian làm việc cộng tác với thành viên nhóm',
  business_des3: 'Chia sẻ và chỉnh sửa văn bản chép lời trong nhóm của bạn.',
  business_des4: 'Kiểm soát quyền chia sẻ',
  coming_soon: 'Sắp ra mắt',
  get_started: 'Bắt đầu',
  bill_annually_x: 'Tính phí {x} hàng năm',
  no_credit_card_required: 'Không cần thẻ tín dụng',
  save_x: 'Tiết kiệm {x}%',
  price_per_month: ' / tháng',
  not_available: 'Không có sẵn',
  pricing_title: 'Giá cả',
  upgrade: 'Nâng cấp',
  ask_question_to_your_audio: 'Đặt câu hỏi cho âm thanh của bạn',
  monthly_minutes: 'Phút hàng tháng',
  payment_interval: 'Chu kỳ thanh toán',
  annually: 'Hàng năm',
  billing: 'Thanh toán',
  subscription_plan: 'Gói đăng ký',
  please_cancel_your_current_subscription_first:
    'Vui lòng hủy đăng ký hiện tại của bạn trước',
  please_cancel_your_current_subscription_first_des:
    'Để thay đổi gói đăng ký của bạn, bạn cần phải hủy gói đăng ký hiện tại trước.',
  cancel_subscription_instruction:
    'Truy cập Cài đặt > Thanh toán > Hủy Đăng ký',
  website_version: 'Phiên bản trang web',
  change_to_annual_plan: 'Chuyển sang gói hàng năm',
  update_annual_mobile_platform_reminder: 'Nhắc nhở',
  update_annual_mobile_platform_reminder_des:
    'Vui lòng lưu ý rằng bạn cần phải hủy đăng ký hiện tại của mình trên {x} trước khi chuyển sang gói hàng năm. Nếu không, bạn sẽ bị tính phí hai lần.',
  i_understand: 'Tôi hiểu',
  no_credit: 'Không đủ phút',
  re_submit: 'Gửi lại',
  loading_audio: 'Đang tải âm thanh',
  your_password: 'Mật khẩu của bạn',
  reset_password_email_sent:
    'Một email đã được gửi đến địa chỉ email của bạn. Vui lòng kiểm tra hộp thư đến để đặt lại mật khẩu.',
  home: 'Trang chủ',
  title_a_z: 'Tiêu đề A-Z',
  title_z_a: 'Tiêu đề Z-A',
  older_first: 'Cũ trước',
  newest_first: 'Mới trước',
  table_item_count: '{x} - {y} trong {z} mục',
  starred: 'Đánh dấu sao',
  search: 'Tìm kiếm',
  create_folder: 'Tạo thư mục',
  folder_name: 'Tên thư mục',
  create: 'Tạo',
  export_preview: 'Xem trước xuất khẩu',
  combine_segments: 'Kết hợp các phân đoạn',
  export_audio_only:
    'Bạn chỉ có thể xuất khẩu âm thanh khi âm thanh đang được chép lời.',
  transcribe_remaining_x_minutes: 'Chép lời {x} phút còn lại',
  x_minutes_remain_be_transcribed: '{x} phút còn lại để được chép lời',
  free_member_limited_transcribe_view:
    'Chỉ {x} phút đầu tiên sẽ được chép lời và có thể xem cho thành viên miễn phí',
  upgrade_plan_to_transcribe:
    'Nâng cấp gói của bạn để chép lời toàn bộ âm thanh',
  only_paid_user_can_use:
    'Chỉ người dùng trả phí mới có thể chép lời các phút còn lại',
  converting_to_audio: 'Đang chuyển đổi thành âm thanh',
}
