<template>
  <CSidebar
    position="fixed"
    selfHiding="md"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <a class="header-logo" href="/">
      <img class="header-logo__img" :src="logo" />SoundType AI
    </a>
    <div
      style="
        width: 100%;
        padding-left: var(--st-sidebar-padding);
        padding-right: var(--st-sidebar-padding);
        margin-top: 12px;
        margin-bottom: 32px;
      "
    >
      <TranscribeButton :is-full-width="true" />
    </div>
    <AppSidebarNav />
    <ul class="sidebar-nav" style="flex: none">
      <li class="nav-item">
        <a class="nav-link">
          <AccountCircleIcon />
          <div>
            <!--            <div>{{ userEmail }}</div>-->
            <div>{{ subscription }}</div>
            <div>
              {{ $t('x_minutes', { x: (remainingMinutes / 60).toFixed(0) }) }}
            </div>
          </div>
        </a>
      </li>
      <!--      <li class="nav-item" v-if="!isPurchased || !prodMode">-->
      <!--        <a class="nav-link" @click="() => (isShowPurchaseDialog = true)">-->
      <!--          <TagHeartIcon />-->
      <!--          {{ $t('subscription') }}-->
      <!--        </a>-->
      <!--      </li>-->
      <!--      <li class="nav-item">-->
      <!--        <a class="nav-link" href="/#/plan/">-->
      <!--          <TagHeartIcon />-->
      <!--          {{ $t('subscription_plan') }}-->
      <!--        </a>-->
      <!--      </li>-->
      <!--      <li class="nav-item">-->
      <!--        <a class="nav-link" href="/#/billing/">-->
      <!--          <DownloadBoxOutlineIcon />-->
      <!--          {{ $t('billing') }}-->
      <!--        </a>-->
      <!--      </li>-->
      <!--      <li class="nav-item">-->
      <!--        <a class="nav-link" href="/#/download/">-->
      <!--          <DownloadBoxOutlineIcon />-->
      <!--          {{ $t('download_app') }}-->
      <!--        </a>-->
      <!--      </li>-->
      <!--      <li class="nav-item">-->
      <!--        <a class="nav-link" href="/#/settings">-->
      <!--          <CogOutlineIcon />-->
      <!--          {{ $t('settings') }}-->
      <!--        </a>-->
      <!--      </li>-->
    </ul>
    <div
      style="
        color: black;
        font-size: 12px;
        text-align: center;
        margin-bottom: 8px;
      "
    >
      v{{ version }}
    </div>
    <PurchaseModal
      :visible="isShowPurchaseDialog"
      @close="() => (isShowPurchaseDialog = false)"
    />
    <!--    <CSidebarToggler-->
    <!--      class="d-none d-lg-flex"-->
    <!--      @click="$store.commit('toggleUnfoldable')"-->
    <!--    />-->
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
import TranscribeButton from '@/components/TranscribeButton.vue'
import AccountCircleIcon from 'vue-material-design-icons/AccountCircle.vue'
import PurchaseModal from '@/components/purchases/PurchaseModal.vue'
import { AuthHelper } from '@/api_client'
import logo from '../assets/logo_blue_small.png'

export default {
  name: 'AppSidebar',
  components: {
    TranscribeButton,
    AppSidebarNav,
    AccountCircleIcon,
    PurchaseModal,
  },
  data() {
    return {
      version: '1.6.9c',
      logo,
      isShowPurchaseDialog: false,
      isShowUploadDialog: false,
      userEmail: AuthHelper.getEmail(),
      subscription: AuthHelper.getSubscriptionPlanName(),
      isPurchased: AuthHelper.isPaidUser(),
      prodMode: process.env.NODE_ENV === 'production',
      remainingMinutes: AuthHelper.getCredits(),
    }
  },
  created() {
    this.refreshUser()
  },
  methods: {
    async refreshUser() {
      try {
        const user = await this.apiClient.getUser()
        // console.log('user')
        // console.log(user)
        AuthHelper.userLogin(user)
        this.$i18n.locale = user.display_language
        this.subscription = AuthHelper.getSubscriptionPlanName()
        this.remainingMinutes = user.credits
      } catch (e) {
        console.log(e)
      }
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const store = useStore()

    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>
<style lang="scss" scoped>
.sc-logo {
  color: #6be69b;
  padding: 2px 12px 0 12px;
  font-weight: 700;
  font-size: 22px;
  letter-spacing: -1px;
  text-transform: uppercase;
}

.nav-link {
  span {
    margin-right: 20px;
  }
}

.nav-link:hover {
  cursor: pointer;
}

.header-logo {
  line-height: 24px;
  font-size: 18px;
  padding-left: var(--st-sidebar-padding);
  padding-right: var(--st-sidebar-padding);
  padding-top: var(--st-sidebar-padding);
  display: flex;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
  color: black;
  margin-bottom: 20px;

  &__img {
    width: 36px;
    margin-right: 12px;
  }
}

//
//:deep(.nav-link.active .nav-icon) {
//  color: red !important;
//}
//
//.nav-link,
//:deep(.nav-link),
//:deep(.nav-icon) {
//  color: #5f6475 !important;
//}
</style>
