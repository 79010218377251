export const vi = {
  sign_in: 'Đăng nhập',
  email: 'Email',
  password: 'Mật khẩu',
  i_agree_to_toc: 'Tôi đồng ý với các điều khoản và điều kiện.',
  terms_and_conditions: 'Điều khoản và điều kiện',
  privacy_policy: 'Chính sách bảo mật',
  already_have_an_account: 'Đã có tài khoản?',
  sign_up: 'Đăng ký',
  dont_have_an_account: 'Chưa có tài khoản?',
  forgot_password: 'Quên mật khẩu?',
  email_already_in_use: 'Email đã được sử dụng',
  weak_password: 'Mật khẩu yếu',
  too_many_attempts: 'Quá nhiều lần thử',
  invalid_email_or_password: 'Email hoặc mật khẩu không hợp lệ',
  user_already_registered: 'Người dùng đã đăng ký',
  unexpected_error: 'Lỗi không mong muốn',
  audios: 'Âm thanh',
  name: 'Tên',
  auto: 'Tự động',
  auto_detect: 'Tự động nhận dạng',
  speaker_num: 'Số người nói',
  speaker_num_reason:
    'Nếu bạn cho chúng tôi biết số người nói, chúng tôi sẽ cung cấp kết quả chuyển văn bản tốt hơn.',
  audio_language: 'Ngôn ngữ âm thanh',
  your_email: 'Email của bạn',
  email_result:
    'Chúng tôi sẽ gửi cho bạn một email với kết quả chuyển văn bản.',
  provide_language:
    'Nếu bạn cho chúng tôi biết ngôn ngữ âm thanh, chúng tôi sẽ cung cấp kết quả chuyển văn bản tốt hơn.',
  upload_audio: 'Tải lên âm thanh',
  upload_file: 'Tải lên tệp',
  process_audio: 'Xử lý tệp của bạn',
  process_audio_des:
    'Vui lòng đợi trong khi âm thanh của bạn được chuyển văn bản. Việc này có thể mất vài phút.',
  uploading: 'Đang tải lên',
  pending: 'Đang chờ',
  uploaded: 'Đã tải lên',
  failed: 'Thất bại',
  status: 'Trạng thái',
  language: 'Ngôn ngữ',
  speakers: 'Người nói',
  duration: 'Thời lượng',
  done: 'Hoàn thành',
  close: 'Đóng',
  queuing: 'Đang chờ xếp hàng',
  created_at: 'Được tạo vào',
  records: 'Bản ghi',
  transcribe: 'Chuyển văn bản',
  delete: 'Xóa',
  processing: 'Đang xử lý',
  from_youtube: 'Từ Youtube',
  upload_files: 'Tải lên tệp',
  place_youtube_link_here: 'Đặt liên kết Youtube ở đây',
  submit: 'Gửi',
  email_notification:
    'Thời gian hoàn thành phụ thuộc vào độ dài và độ phức tạp của tệp âm thanh. Thông thường, bạn có thể nhận được bản chuyển văn bản của mình trong vài giờ. Chúng tôi sẽ thông báo cho bạn qua email ngay khi nó sẵn sàng.',
  filters: 'Bộ lọc',
  filter: 'Lọc',
  replace: 'Thay thế',
  share_and_export: 'Chia sẻ và Xuất bản',
  replace_all: 'Thay thế tất cả',
  next: 'Tiếp theo',
  previous: 'Trước đó',
  find: 'Tìm',
  replace_with: 'Thay thế bằng',
  x_of_x: '{current} của {total}',
  scroll_lock: 'Khóa cuộn',
  tags: 'Thẻ',
  bookmark: 'Đánh dấu',
  audio: 'Âm thanh',
  cancel: 'Hủy',
  export: 'Xuất bản',
  export_format: 'Định dạng xuất bản',
  copy_text: 'Sao chép văn bản',
  monologue: 'Độc thoại',
  include_timestamps: 'Bao gồm dấu thời gian',
  include_speaker_names: 'Bao gồm tên người nói',
  combine_same_speaker: 'Kết hợp các đoạn của cùng một người nói',
  export_mode: 'Chế độ xuất bản',
  no_preview: 'Không có xem trước',
  copy: 'Sao chép',
  copied: 'Đã sao chép',
  unlock_pro_feature:
    "Để mở khóa tính năng này, nâng cấp lên gói đăng ký 'Pro'.",
  pro_features: 'Tính năng Pro',
  pro_minutes: '1800 phút / tháng',
  pro_interact_with_audio: 'Tương tác với âm thanh',
  pro_interact_with_audio_des: 'Hỏi câu hỏi cho âm thanh của bạn',
  pro_summary: 'Tóm tắt AI',
  pro_summary_des: 'Tóm tắt âm thanh của bạn',
  pro_priority: 'Ưu tiên hàng đợi',
  pro_priority_des: 'Xử lý âm thanh ưu tiên',
  pro_multi_speakers: 'Nhận dạng nhiều người nói',
  pro_multi_speakers_des:
    'Phát hiện và gán nhãn chính xác các người nói khác nhau',
  redirect_to_stripe:
    'Bạn sẽ được chuyển hướng đến Stripe để hoàn thành thanh toán.',
  x_per_month: '{x} / Tháng',
  x_per_year: '{x} / Năm',
  trash: 'Thùng rác',
  dashboard: 'Bảng điều khiển',
  logout: 'Đăng xuất',
  are_you_sure_to_delete: 'Bạn có chắc chắn muốn xóa các bản ghi đã chọn?',
  restore: 'Khôi phục',
  are_you_sure_to_restore:
    'Bạn có chắc chắn muốn khôi phục các bản ghi đã chọn?',
  deleted_at: 'Đã xóa vào',
  free_member_limited_view:
    'Thành viên miễn phí chỉ có quyền truy cập vào {x} phút đầu tiên của kết quả chuyển văn bản.',
  hidden_segment: '{x} đoạn bị ẩn sau {y} phút.',
  settings: 'Cài đặt',
  subscription: 'Đăng ký',
  display_language: 'Ngôn ngữ giao diện người dùng',
  default_transcription_language: 'Ngôn ngữ chuyển văn bản mặc định',
  contact_us: 'Liên hệ chúng tôi',
  security: 'Bảo mật',
  about_us: 'Về chúng tôi',
  download_app: 'Tải ứng dụng',
  download: 'Tải về',
  app_store: 'App Store',
  play_store: 'Play Store',
  upload_voice: 'Tải lên giọng nói',
  no_audio_found: 'Không tìm thấy âm thanh',
  please_input_valid_email_first: 'Vui lòng nhập email hợp lệ trước',
  handling_audio:
    'Chúng tôi đang chuyển văn bản âm thanh của bạn. Vui lòng đợi một chút.',
  welcome_to_leave:
    'Bạn có thể rời khỏi trang này. Chúng tôi sẽ gửi cho bạn một email với kết quả chuyển văn bản.',
  send_your_result:
    'Chúng tôi sẽ gửi cho bạn một email với kết quả chuyển văn bản.',
  transcribe_audio_thank:
    'Cảm ơn bạn đã sử dụng dịch vụ chuyển văn bản của chúng tôi! Chúng tôi đang làm việc chăm chỉ để chuyển văn bản âm thanh của bạn và rất mong kiên nhẫn của bạn.',
  transcribe_audio_no_stay:
    'Việc chuyển văn bản của bạn có thể mất một chút thời gian, nhưng đừng lo, bạn không cần phải ở trang này. Chúng tôi sẽ gửi cho bạn kết quả qua email ngay khi nó sẵn sàng.',
  date: 'Ngày',
  sign_up_to_access_more_functions: 'Đăng ký để truy cập thêm chức năng',
  you_can_use_functions:
    'Sau khi đăng nhập, bạn có thể sử dụng các chức năng sau',
  know_more: 'Tìm hiểu thêm',
  modify_transcription: 'Chỉnh sửa chuyển văn bản văn bản',
  play_audio: 'Phát âm thanh',
  content_filters: 'Bộ lọc nội dung',
  export_diff_type: 'Xuất bản các loại tệp khác nhau',
  screenshots: 'Ảnh chụp màn hình',
  free_trial: 'Dùng thử miễn phí',
  pricing: 'Giá cả',
  faq: 'Câu hỏi thường gặp',
  contact: 'Liên hệ',
  login: 'Đăng nhập',
  register: 'Đăng ký',
  please_login_before_purchase: 'Vui lòng đăng nhập trước khi mua hàng',
  no_records_found: 'Không tìm thấy hồ sơ',
  x_minutes: '{x} Phút',
  confirm: 'Xác nhận',
  notification_mobile_app: 'Thông báo ứng dụng di động',
  notification_mobile_app_des:
    'Nhận thông báo trên ứng dụng di động khi kết quả phiên dịch sẵn sàng',
  notification_email: 'Thông báo qua email',
  notification_email_des:
    'Nhận thông báo qua email khi kết quả phiên dịch sẵn sàng',
  video: 'Video',
  or: 'Hoặc',
  sign_in_with_google: 'Đăng nhập bằng Google',
  sign_in_with_apple: 'Đăng nhập bằng Apple',
  login_method: 'Phương thức đăng nhập',
  delete_account: 'Xóa tài khoản',
  delete_account_confirm_3_time: 'Nhấn nút xóa 3 lần để xác nhận',
  popup_blocked: 'Chặn cửa sổ bật lên',
  account_info: 'Thông tin tài khoản',
  redeem: 'Đổi',
  redeem_code: 'Đổi mã',
  summary_des1:
    'Dịch vụ tóm tắt cung cấp một bản tóm tắt kết quả chuyển văn bản. Nó có thể tóm tắt kết quả chuyển văn bản thành một vài câu.',
  summary_des2: 'Bản tóm tắt cũ của bạn sẽ được thay thế bằng bản tóm tắt mới.',
  consume_transcription_quota: 'Tiêu thụ hạn mức chuyển văn bản',
  summary: 'Tóm tắt',
  query: 'Truy vấn',
  mins_per_query: '{x} phút mỗi truy vấn',
  free_user_service_once:
    'Người dùng miễn phí chỉ có thể sử dụng dịch vụ này một lần.',
  free_user_summary:
    'Người dùng miễn phí chỉ có thể sử dụng dịch vụ này một lần. Vui lòng nâng cấp lên gói Pro để tóm tắt nhiều âm thanh hơn.',
  free_user_query:
    'Người dùng miễn phí chỉ có thể sử dụng dịch vụ này một lần. Vui lòng nâng cấp lên gói Pro để đặt thêm truy vấn.',
  information_not_found: 'Không tìm thấy thông tin',
  permission_deny: 'Từ chối quyền',
  please_login_to_check_the_result: 'Vui lòng đăng nhập để kiểm tra kết quả',
  speaker_: 'Loa {x}',
  audio_length: 'Độ dài âm thanh',
  delete_permanently: 'Xóa vĩnh viễn',
  are_you_sure_to_delete_permanently:
    'Bạn có chắc chắn muốn xóa vĩnh viễn không?',
  delete_audios_move_to_bin:
    'Các bản ghi đã xóa sẽ được di chuyển vào thùng rác. Tất cả các bản ghi trong thùng rác sẽ được xóa sau 30 ngày.',
  payment_id: 'ID thanh toán',
  current_plan: 'Gói hiện tại',
  plan_name: 'Tên gói',
  expire_date: 'Ngày hết hạn',
  subscription_method: 'Phương thức đăng ký',
  payment_method: 'Phương thức thanh toán',
  payment_history: 'Lịch sử thanh toán',
  amount: 'Số tiền',
  invoice: 'Hóa đơn',
  receipt: 'Biên lai',
  link: 'Liên kết',
  edit: 'Chỉnh sửa',
  back: 'Quay lại',
  return: 'Trở về',
  cancel_subscription: 'Hủy đăng ký',
  cancel_subscription_google_play:
    'Trên thiết bị Android của bạn > Chuyển đến Google Play Store > Tài khoản > Thanh toán và đăng ký > Đăng ký > SoundType AI > Hủy đăng ký',
  cancel_subscription_apple_store:
    'Trên thiết bị iOS của bạn > Chuyển đến Cài đặt > [tên của bạn] > Đăng ký > SoundType AI > Hủy đăng ký',
  delete_account_des: 'Xóa tài khoản sẽ xóa tất cả dữ liệu của bạn vĩnh viễn.',
  delete_account_subscription:
    'Nếu bạn có một đăng ký, vui lòng hủy đăng ký của bạn trước.',
  keep_subscription: 'Giữ Đăng ký',
  cancel_subscription_des:
    'Đăng ký của bạn sẽ được hủy vào cuối chu kỳ thanh toán hiện tại ({x}). Sau ngày đó, bạn sẽ được hạ cấp xuống gói miễn phí, và bạn sẽ không còn có thể truy cập vào các tính năng của gói Pro:',
  cancel_subscription_feature1: 'Bản ghi',
  cancel_subscription_feature1_des:
    'Bạn chỉ có thể xem {x} phút đầu tiên của các bản ghi của mình.',
  cancel_subscription_feature2: 'Thời gian phiên âm',
  cancel_subscription_feature2_des:
    'Bạn chỉ có thể phiên âm {x} phút mỗi tháng.',
  cancel_subscription_count: 'Vui lòng nhấn nút {x} lần để xác nhận',
  next_payment_date: 'Ngày thanh toán tiếp theo',
  basic_plan: 'Gói Cơ bản',
  resume_subscription: 'Tiếp tục đăng ký',
  active: 'Hoạt động',
  expired: 'Đã hết hạn',
  auto_renewal_clause: 'Điều khoản tự động gia hạn',
  auto_renewal_payment_web:
    'Đăng ký của bạn sẽ được tự động gia hạn trừ khi bạn hủy bỏ ít nhất 24 giờ trước khi kỳ hiện tại kết thúc. Tài khoản của bạn sẽ được tính phí gia hạn trong vòng 24 giờ trước khi kỳ hiện tại kết thúc. Bạn có thể quản lý và hủy bỏ đăng ký của mình bằng cách đi đến Cài đặt > Đăng ký.',
  auto_renewal_terms_and_conditions:
    'Bằng cách đăng ký, bạn đồng ý với Điều khoản và Điều kiện cũng như Chính sách Bảo mật của chúng tôi.',
  payment: 'Thanh toán',
  monthly: 'Hàng tháng',
  annual: 'Hàng năm',
  minutes_per_month: '{x} phút/tháng',
  buy_now: 'Mua Ngay',
  yearly: 'Hàng năm',
  yearly_save: 'Tiết kiệm đến {x}%',
  pro_plan: 'Pro',
  business_plan: 'Doanh nghiệp',
  enterprise_plan: 'Doanh nghiệp lớn',
  everything_in_basic: 'Tất cả trong Gói Cơ bản, cộng thêm',
  everything_in_pro: 'Tất cả trong Gói Pro, cộng thêm',
  everything_in_business: 'Tất cả trong Gói Doanh nghiệp, cộng thêm',
  ai_chat_x: 'Trò chuyện AI x {x}',
  ai_summary_x: 'Tóm tắt AI x {x}',
  max_x_minutes_per_transcription: 'Tối đa {x} phút mỗi bản ghi chép',
  basic_des2: 'Nhận diện người nói',
  basic_des3: 'Ghi chép tệp âm thanh/video',
  basic_des4: 'Xem xét & chỉnh sửa bản ghi chép',
  pro_des2: 'Hàng đợi Ưu tiên',
  pro_des3: 'Xuất ở định dạng SRT',
  pro_des4: 'Tóm tắt AI không giới hạn',
  business_des1: 'Báo cáo phân tích sử dụng',
  business_des2: 'Không gian làm việc cộng tác với thành viên trong nhóm',
  business_des3: 'Chia sẻ và chỉnh sửa bản ghi chép trong nhóm của bạn.',
  business_des4: 'Kiểm soát quyền chia sẻ',
  coming_soon: 'Sắp có',
  get_started: 'Bắt đầu',
  bill_annually_x: 'Hóa đơn {x} hàng năm',
  no_credit_card_required: 'Không yêu cầu thẻ tín dụng',
  save_x: 'Tiết kiệm {x}%',
  price_per_month: ' / tháng',
  not_available: 'Không khả dụng',
  pricing_title: 'Bảng Giá',
  upgrade: 'Nâng cấp',
  ask_question_to_your_audio: 'Đặt câu hỏi cho audio của bạn',
  monthly_minutes: 'Phút hàng tháng',
  payment_interval: 'Khoảng thanh toán',
  annually: 'Hàng năm',
  billing: 'Thanh toán',
  subscription_plan: 'Gói đăng ký',
  please_cancel_your_current_subscription_first:
    'Vui lòng hủy bỏ gói đăng ký hiện tại của bạn trước',
  please_cancel_your_current_subscription_first_des:
    'Để thay đổi gói đăng ký của bạn, bạn cần phải hủy gói đăng ký hiện tại trước.',
  cancel_subscription_instruction: 'Đi tới Cài đặt > Thanh toán > Hủy Đăng Ký',
  website_version: 'Phiên bản Website',
  change_to_annual_plan: 'Chuyển sang Gói Hàng Năm',
  update_annual_mobile_platform_reminder: 'Nhắc nhở',
  update_annual_mobile_platform_reminder_des:
    'Xin lưu ý rằng bạn cần hủy bỏ gói đăng ký hiện tại trên {x} trước khi chuyển sang gói hàng năm. Nếu không, bạn sẽ bị tính phí hai lần.',
  i_understand: 'Tôi đã hiểu',
  no_credit: 'Không đủ phút',
  re_submit: 'Gửi lại',
  loading_audio: 'Đang tải âm thanh',
  your_password: 'Mật khẩu của bạn',
  reset_password_email_sent:
    'Một email đã được gửi đến địa chỉ email của bạn. Vui lòng kiểm tra hộp thư đến để đặt lại mật khẩu của bạn.',
  home: 'Trang chủ',
  title_a_z: 'Tiêu đề A-Z',
  title_z_a: 'Tiêu đề Z-A',
  older_first: 'Cũ nhất trước',
  newest_first: 'Mới nhất trước',
  table_item_count: '{x} - {y} trong tổng số {z} mục',
  starred: 'Được gắn sao',
  search: 'Tìm kiếm',
  create_folder: 'Tạo thư mục',
  folder_name: 'Tên thư mục',
  create: 'Tạo',
  export_preview: 'Xuất bản xem trước',
  combine_segments: 'Kết hợp các phân đoạn',
  export_audio_only:
    'Bạn chỉ có thể xuất âm thanh khi âm thanh đang được chép lại.',
  transcribe_remaining_x_minutes: 'Chép lại {x} phút còn lại',
  x_minutes_remain_be_transcribed: 'Còn {x} phút để chép lại',
  free_member_limited_transcribe_view:
    'Chỉ có {x} phút đầu tiên sẽ được chép lại và hiển thị cho thành viên miễn phí',
  upgrade_plan_to_transcribe:
    'Nâng cấp gói của bạn để chép lại toàn bộ âm thanh',
  only_paid_user_can_use:
    'Chỉ người dùng trả phí mới có thể chép lại những phút còn lại',
  converting_to_audio: 'Đang chuyển đổi thành âm thanh',
}
