class SupportedLanguages {
  static DISPLAY_LANGUAGES = [
    { text: 'English', value: 'en' },
    { text: 'German', value: 'de' },
    { text: 'Spanish', value: 'es' },
    { text: 'French', value: 'fr' },
    { text: 'हिन्दी', value: 'hi' },
    { text: 'Bahasa Indonesia', value: 'id' },
    { text: 'Italian', value: 'it' },
    { text: '日本語', value: 'ja' },
    { text: '한국인', value: 'ko' },
    { text: 'Nederlands', value: 'nl' },
    { text: 'Português', value: 'pt' },
    { text: 'Pусский язык', value: 'ru' },
    { text: 'ไทย', value: 'th' },
    { text: 'Türkçe', value: 'tr' },
    { text: 'Tiếng Việt', value: 'vi' },
    { text: '繁體中文', value: 'zh' },
    { text: '简体中文', value: 'zh-hans' },
  ]
  static LANGUAGES = [
    { text: '', value: '', en_text: '' },
    { text: 'English', value: 'en', en_text: 'English' },
    { text: '中文(繁體)', value: 'zh_tw', en_text: 'Traditional Chinese' },
    { text: '中文(简体)', value: 'zh_cn', en_text: 'Simplified Chinese' },
    { text: '中文(香港)', value: 'yue', en_text: 'Cantonese' },
    { text: '日本語', value: 'ja', en_text: 'Japanese' },
    { text: '한국어', value: 'ko', en_text: 'Korean' },
    { text: 'Deutsch', value: 'de', en_text: 'German' },
    { text: 'Español', value: 'es', en_text: 'Spanish' },
    { text: 'Français', value: 'fr', en_text: 'French' },
    { text: 'हिन्दी', value: 'hi', en_text: 'Hindi' },
    { text: 'Italiano', value: 'it', en_text: 'Italian' },
    { text: 'Nederlands', value: 'nl', en_text: 'Dutch' },
    { text: 'Português', value: 'pt', en_text: 'Portuguese' },
    { text: 'Русский', value: 'ru', en_text: 'Russian' },
    { text: 'Tiếng Việt', value: 'vi', en_text: 'Vietnamese' },
    // { text: "廣東話(書面語)", value: "zh_hk", en_text: "Traditional Chinese (Hong Kong)" },
    { text: 'Afrikaans', value: 'af', en_text: 'Afrikaans' },
    { text: 'العربية', value: 'ar', en_text: 'Arabic' },
    { text: 'Հայերեն', value: 'hy', en_text: 'Armenian' },
    { text: 'Azərbaycan', value: 'az', en_text: 'Azerbaijani' },
    { text: 'Беларуская', value: 'be', en_text: 'Belarusian' },
    { text: 'Bosanski', value: 'bs', en_text: 'Bosnian' },
    { text: 'Български', value: 'bg', en_text: 'Bulgarian' },
    { text: 'Català', value: 'ca', en_text: 'Catalan' },
    { text: 'Hrvatski', value: 'hr', en_text: 'Croatian' },
    { text: 'Čeština', value: 'cs', en_text: 'Czech' },
    { text: 'Dansk', value: 'da', en_text: 'Danish' },
    { text: 'Eesti', value: 'et', en_text: 'Estonian' },
    { text: 'Suomi', value: 'fi', en_text: 'Finnish' },
    { text: 'Galego', value: 'gl', en_text: 'Galician' },
    { text: 'Ελληνικά', value: 'el', en_text: 'Greek' },
    { text: 'עברית', value: 'he', en_text: 'Hebrew' },
    { text: 'Magyar', value: 'hu', en_text: 'Hungarian' },
    { text: 'Íslenska', value: 'is', en_text: 'Icelandic' },
    { text: 'Bahasa Indonesia', value: 'id', en_text: 'Indonesian' },
    { text: 'ಕನ್ನಡ', value: 'kn', en_text: 'Kannada' },
    { text: 'Latviešu', value: 'lv', en_text: 'Latvian' },
    { text: 'Lietuvių', value: 'lt', en_text: 'Lithuanian' },
    { text: 'Македонски', value: 'mk', en_text: 'Macedonian' },
    { text: 'Bahasa Melayu', value: 'ms', en_text: 'Malay' },
    { text: 'मराठी', value: 'mar', en_text: 'Marathi' },
    { text: 'नेपाली', value: 'ne', en_text: 'Nepali' },
    { text: 'Norsk', value: 'no', en_text: 'Norwegian' },
    { text: 'فارسی', value: 'fa', en_text: 'Persian' },
    { text: 'Polski', value: 'pl', en_text: 'Polish' },
    { text: 'Română', value: 'ro', en_text: 'Romanian' },
    { text: 'Српски', value: 'sr', en_text: 'Serbian' },
    { text: 'Slovenčina', value: 'sk', en_text: 'Slovak' },
    { text: 'Slovenščina', value: 'sl', en_text: 'Slovenian' },
    { text: 'Kiswahili', value: 'sw', en_text: 'Swahili' },
    { text: 'Svenska', value: 'sv', en_text: 'Swedish' },
    { text: 'Tagalog', value: 'tl', en_text: 'Tagalog' },
    { text: 'தமிழ்', value: 'ta', en_text: 'Tamil' },
    { text: 'ไทย', value: 'th', en_text: 'Thai' },
    { text: 'Türkçe', value: 'tr', en_text: 'Turkish' },
    { text: 'Українська', value: 'uk', en_text: 'Ukrainian' },
    { text: 'اردو', value: 'ur', en_text: 'Urdu' },
    { text: 'Cymraeg', value: 'cy', en_text: 'Welsh' },
    { text: '中文', value: 'zh', en_text: 'Chinese' },
    { text: 'Latina', value: 'la', en_text: 'Latin' },
    { text: 'Māori', value: 'mi', en_text: 'Maori' },
    { text: 'മലയാളം', value: 'ml', en_text: 'Malayalam' },
    { text: 'తెలుగు', value: 'te', en_text: 'Telugu' },
    { text: 'বাংলা', value: 'bn', en_text: 'Bengali' },
    { text: 'Brezhoneg', value: 'br', en_text: 'Breton' },
    { text: 'Euskara', value: 'eu', en_text: 'Basque' },
    { text: 'Монгол', value: 'mn', en_text: 'Mongolian' },
    { text: 'Қазақ', value: 'kk', en_text: 'Kazakh' },
    { text: 'Shqip', value: 'sq', en_text: 'Albanian' },
    { text: 'ਪੰਜਾਬੀ', value: 'pa', en_text: 'Punjabi' },
    { text: 'සිංහල', value: 'si', en_text: 'Sinhala' },
    { text: 'ខ្មែរ', value: 'km', en_text: 'Khmer' },
    { text: 'ChiShona', value: 'sn', en_text: 'Shona' },
    { text: 'Yorùbá', value: 'yo', en_text: 'Yoruba' },
    { text: 'Soomaali', value: 'so', en_text: 'Somali' },
    { text: 'Occitan', value: 'oc', en_text: 'Occitan' },
    { text: 'ქართული', value: 'ka', en_text: 'Georgian' },
    { text: 'Тоҷикӣ', value: 'tg', en_text: 'Tajik' },
    { text: 'سنڌي', value: 'sd', en_text: 'Sindhi' },
    { text: 'ગુજરાતી', value: 'gu', en_text: 'Gujarati' },
    { text: 'አማርኛ', value: 'am', en_text: 'Amharic' },
    { text: 'ייִדיש', value: 'yi', en_text: 'Yiddish' },
    { text: 'ລາວ', value: 'lo', en_text: 'Lao' },
    { text: 'Oʻzbek', value: 'uz', en_text: 'Uzbek' },
    { text: 'Føroyskt', value: 'fo', en_text: 'Faroese' },
    { text: 'Kreyòl Ayisyen', value: 'ht', en_text: 'Haitian Creole' },
    { text: 'پښتو', value: 'ps', en_text: 'Pashto' },
    { text: 'Türkmen', value: 'tk', en_text: 'Turkmen' },
    { text: 'Nynorsk', value: 'nn', en_text: 'Nynorsk' },
    { text: 'Malti', value: 'mt', en_text: 'Maltese' },
    { text: 'संस्कृतम्', value: 'sa', en_text: 'Sanskrit' },
    { text: 'Lëtzebuergesch', value: 'lb', en_text: 'Luxembourgish' },
    { text: 'မြန်မာ', value: 'my', en_text: 'Myanmar' },
    { text: 'བོད་ཡིག', value: 'bo', en_text: 'Tibetan' },
    { text: 'Malagasy', value: 'mg', en_text: 'Malagasy' },
    { text: 'অসমীয়া', value: 'as', en_text: 'Assamese' },
    { text: 'татар теле', value: 'tt', en_text: 'Tatar' },
    { text: 'ʻŌlelo Hawaiʻi', value: 'haw', en_text: 'Hawaiian' },
    { text: 'Lingála', value: 'ln', en_text: 'Lingala' },
    { text: 'Hausa', value: 'ha', en_text: 'Hausa' },
    { text: 'Башҡорт', value: 'ba', en_text: 'Bashkir' },
    { text: 'Basa Jawa', value: 'jw', en_text: 'Javanese' },
    { text: 'Basa Sunda', value: 'su', en_text: 'Sundanese' },
  ]

  static getLanguageByValue(value) {
    const tmpValue = value == 'zh' ? 'zh_tw' : value
    return (
      SupportedLanguages.LANGUAGES.find(
        (language) => language.value === tmpValue,
      )?.text ?? 'Auto'
    )
  }

  static sortByValue() {
    return SupportedLanguages.LANGUAGES.slice().sort((a, b) =>
      a.value.localeCompare(b.value),
    )
  }
}

export default SupportedLanguages
