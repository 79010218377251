export const zh_hans = {
  sign_in: '登录',
  email: '邮箱',
  password: '密码',
  i_agree_to_toc: '我同意条款和条件。',
  terms_and_conditions: '条款和条件',
  privacy_policy: '隐私政策',
  already_have_an_account: '已有账户？',
  sign_up: '注册',
  dont_have_an_account: '还没有账户？',
  forgot_password: '忘记密码？',
  email_already_in_use: '邮箱已被使用',
  weak_password: '密码强度不够',
  too_many_attempts: '尝试次数过多',
  invalid_email_or_password: '邮箱或密码无效',
  user_already_registered: '用户已注册',
  unexpected_error: '意外错误',
  audios: '音频',
  name: '姓名',
  auto: '自动',
  auto_detect: '自动检测',
  speaker_num: '演讲者数量',
  speaker_num_reason: '如果告诉我们演讲者数量，我们将提供更好的转录结果。',
  audio_language: '音频语言',
  your_email: '您的邮箱',
  email_result: '我们将通过电子邮件发送给您转录结果。',
  provide_language: '如果告诉我们音频语言，我们将提供更好的转录结果。',
  upload_audio: '上传音频',
  upload_file: '上传文件',
  process_audio: '处理您的文件',
  process_audio_des: '请等待音频转录完成。这可能需要几分钟时间。',
  uploading: '上传中',
  pending: '等待中',
  uploaded: '已上传',
  failed: '失败',
  status: '状态',
  language: '语言',
  speakers: '演讲者',
  duration: '时长',
  done: '完成',
  close: '关闭',
  queuing: '排队中',
  created_at: '创建于',
  records: '记录',
  transcribe: '转录',
  delete: '删除',
  processing: '处理中',
  from_youtube: '来自YouTube',
  upload_files: '上传文件',
  place_youtube_link_here: '在此处输入YouTube链接',
  submit: '提交',
  email_notification:
    '转录结果的完成时间取决于音频文件的长度和复杂性。通常，您可以在几个小时内收到转录结果的电子邮件通知。',
  filters: '筛选器',
  filter: '筛选',
  replace: '替换',
  share_and_export: '分享和导出',
  replace_all: '全部替换',
  next: '下一个',
  previous: '上一个',
  find: '查找',
  replace_with: '替换为',
  x_of_x: '{current}/{total}',
  scroll_lock: '滚动锁定',
  tags: '标签',
  bookmark: '书签',
  audio: '音频',
  cancel: '取消',
  export: '导出',
  export_format: '导出格式',
  copy_text: '复制文本',
  monologue: '独白',
  include_timestamps: '包含时间戳',
  include_speaker_names: '包含演讲者姓名',
  combine_same_speaker: '合并相同演讲者片段',
  export_mode: '导出模式',
  no_preview: '无预览',
  copy: '复制',
  copied: '已复制',
  unlock_pro_feature: '要解锁此功能，请升级到“专业”订阅计划。',
  pro_features: '专业功能',
  pro_minutes: '1800分钟 / 月',
  pro_interact_with_audio: '与音频互动',
  pro_interact_with_audio_des: '向您的音频提问',
  pro_summary: 'AI摘要',
  pro_summary_des: '对音频进行摘要',
  pro_priority: '优先队列',
  pro_priority_des: '以优先方式处理音频',
  pro_multi_speakers: '多人演讲者识别',
  pro_multi_speakers_des: '准确识别和标记不同的演讲者',
  redirect_to_stripe: '您将被重定向到Stripe以完成付款。',
  x_per_month: '每月{x}',
  x_per_year: '每年{x}',
  trash: '回收站',
  dashboard: '仪表盘',
  logout: '退出登录',
  are_you_sure_to_delete: '您确定要删除所选记录吗？',
  restore: '恢复',
  are_you_sure_to_restore: '您确定要恢复所选记录吗？',
  deleted_at: '删除时间',
  free_member_limited_view: '免费会员只能查看前{x}分钟的转录结果。',
  hidden_segment: '在{y}分钟后，{x}个片段将被隐藏。',
  settings: '设置',
  subscription: '订阅',
  display_language: '用户界面语言',
  default_transcription_language: '默认转录语言',
  contact_us: '联系我们',
  security: '安全',
  about_us: '关于我们',
  download_app: '下载应用',
  download: '下载',
  app_store: 'App Store',
  play_store: 'Play商店',
  upload_voice: '上传语音',
  no_audio_found: '未找到音频',
  please_input_valid_email_first: '请先输入有效的邮箱',
  handling_audio: '我们正在转录您的音频，请稍候。',
  welcome_to_leave: '欢迎离开此页面，我们将通过电子邮件向您发送转录结果。',
  send_your_result: '我们将通过电子邮件向您发送转录结果。',
  transcribe_audio_thank:
    '感谢您使用我们的转录服务！我们正在努力转录您的音频，感谢您的耐心等待。',
  transcribe_audio_no_stay:
    '您的转录可能需要一些时间，但不用担心，您不需要在此页面上停留。我们会在转录结果准备好后通过电子邮件通知您。',
  date: '日期',
  sign_up_to_access_more_functions: '注册以访问更多功能',
  you_can_use_functions: '登录后您可以使用以下功能',
  know_more: '了解更多',
  modify_transcription: '修改文本转录',
  play_audio: '播放音频',
  content_filters: '内容过滤器',
  export_diff_type: '导出不同类型的文件',
  screenshots: '截图',
  free_trial: '免费试用',
  pricing: '定价',
  faq: '常见问题',
  contact: '联系',
  login: '登录',
  register: '注册',
  please_login_before_purchase: '购买前请先登录',
  no_records_found: '未找到记录',
  x_minutes: '{x} 分钟',
  confirm: '确认',
  notification_mobile_app: '手机应用通知',
  notification_mobile_app_des: '当转录结果准备好时，接收手机应用程序通知',
  notification_email: '电子邮件通知',
  notification_email_des: '当转录结果准备好时，接收电子邮件通知',
  video: '视频',
  or: '或者',
  sign_in_with_google: '使用 Google 帐号登录',
  sign_in_with_apple: '使用 Apple 帐号登录',
  login_method: '登录方式',
  delete_account: '删除帐号',
  delete_account_confirm_3_time: '按删除按钮3次确认',
  popup_blocked: '弹出窗口被阻止',
  account_info: '帐户信息',
  redeem: '兑换',
  redeem_code: '兑换码',
  summary_des1: '摘要服务提供转录结果的摘要。它可以将转录结果摘要为几句话。',
  summary_des2: '您的旧摘要将被新摘要替换。',
  consume_transcription_quota: '消耗转录配额',
  summary: '摘要',
  query: '查询',
  mins_per_query: '每个查询{x}分钟',
  free_user_service_once: '免费用户只能使用此服务一次。',
  free_user_summary:
    '免费用户只能使用此服务一次。请升级到专业版以摘要更多音频。',
  free_user_query: '免费用户只能使用此服务一次。请升级到专业版以提出更多查询。',
  information_not_found: '未找到信息',
  permission_deny: '权限拒绝',
  please_login_to_check_the_result: '请登录以查看结果',
  speaker_: '扬声器 {x}',
  audio_length: '音频长度',
  delete_permanently: '永久删除',
  are_you_sure_to_delete_permanently: '您确定要永久删除吗？',
  delete_audios_move_to_bin:
    '被删除的记录将被移至垃圾箱。垃圾箱中的所有记录将在30天后被删除。',
  payment_id: '支付ID',
  current_plan: '当前计划',
  plan_name: '计划名称',
  expire_date: '到期日期',
  subscription_method: '订阅方式',
  payment_method: '支付方式',
  payment_history: '支付历史',
  amount: '金额',
  invoice: '发票',
  receipt: '收据',
  link: '链接',
  edit: '编辑',
  back: '返回',
  return: '返回',
  cancel_subscription: '取消订阅',
  cancel_subscription_google_play:
    '在您的安卓设备上 > 打开Google Play商店 > 账户 > 支付与订阅 > 订阅 > SoundType AI > 取消订阅',
  cancel_subscription_apple_store:
    '在您的iOS设备上 > 前往设置 > [您的名字] > 订阅 > SoundType AI > 取消订阅',
  delete_account_des: '删除账户将永久删除您的所有数据。',
  delete_account_subscription: '如果您有订阅，请先取消订阅。',
  keep_subscription: '保留订阅',
  cancel_subscription_des:
    '您的订阅将在当前计费周期结束时取消({x})。之后，您将降级为免费计划，将无法再访问Pro计划的功能：',
  cancel_subscription_feature1: '录音',
  cancel_subscription_feature1_des: '您只能查看您录音的前{x}分钟。',
  cancel_subscription_feature2: '转录时间',
  cancel_subscription_feature2_des: '您每月只能转录{x}分钟。',
  cancel_subscription_count: '请按按钮{x}次以确认',
  next_payment_date: '下次支付日期',
  basic_plan: '基础计划',
  resume_subscription: '恢复订阅',
  active: '激活',
  expired: '已过期',
  auto_renewal_clause: '自动续订条款',
  auto_renewal_payment_web:
    '除非您在当前期限结束前至少24小时取消订阅，否则您的订阅将自动续订。您的账户将在当前期限结束前24小时内被收取续订费用。您可以通过前往设置 > 订阅来管理和取消您的订阅。',
  auto_renewal_terms_and_conditions:
    '订阅即表示您同意我们的条款和条件以及隐私政策。',
  payment: '支付',
  monthly: '每月',
  annual: '每年',
  minutes_per_month: '{x} 分钟/月',
  buy_now: '立即购买',
  yearly: '年度',
  yearly_save: '最高节省 {x}%',
  pro_plan: '专业版',
  business_plan: '商务版',
  enterprise_plan: '企业版',
  everything_in_basic: '基础版所有功能，外加',
  everything_in_pro: '专业版所有功能，外加',
  everything_in_business: '商务版所有功能，外加',
  ai_chat_x: 'AI 聊天 x {x}',
  ai_summary_x: 'AI 摘要 x {x}',
  max_x_minutes_per_transcription: '每次转录最多 {x} 分钟',
  basic_des2: '说话人识别',
  basic_des3: '音频/视频文件转录',
  basic_des4: '审阅和编辑转录文稿',
  pro_des2: '优先队列',
  pro_des3: '以 SRT 格式导出',
  pro_des4: '无限制 AI 摘要',
  business_des1: '使用分析报告',
  business_des2: '与团队成员协作的工作区',
  business_des3: '在团队内共享和编辑转录文稿。',
  business_des4: '控制共享权限',
  coming_soon: '即将推出',
  get_started: '开始使用',
  bill_annually_x: '每年账单 {x}',
  no_credit_card_required: '无需信用卡',
  save_x: '节省 {x}%',
  price_per_month: ' / 月',
  not_available: '不可用',
  pricing_title: '价格',
  upgrade: '升级',
  ask_question_to_your_audio: '向您的音频提问',
  monthly_minutes: '每月分钟数',
  payment_interval: '付款间隔',
  annually: '每年',
  billing: '账单',
  subscription_plan: '计划',
  please_cancel_your_current_subscription_first: '请先取消您当前的订阅',
  please_cancel_your_current_subscription_first_des:
    '要更改订阅计划，您需要先取消当前的订阅。',
  cancel_subscription_instruction: '转到设置 > 账单 > 取消订阅',
  website_version: '网站版本',
  change_to_annual_plan: '更改为年度计划',
  update_annual_mobile_platform_reminder: '提醒',
  update_annual_mobile_platform_reminder_des:
    '请注意，在更改为年度计划之前，您需要在{x}上取消当前的订阅。否则，您将被收取两次费用。',
  i_understand: '我明白了',
  no_credit: '没有足够的分钟',
  re_submit: '重新提交',
  loading_audio: '加载音频中',
  your_password: '你的密码',
  reset_password_email_sent:
    '一封电子邮件已发送到您的电子邮件地址。请检查您的收件箱以重置您的密码。',
  home: '首页',
  title_a_z: '标题 A-Z',
  title_z_a: '标题 Z-A',
  older_first: '较旧的优先',
  newest_first: '最新的优先',
  table_item_count: '{x} - {y} 共 {z} 项',
  starred: '已加星标',
  search: '搜索',
  create_folder: '创建文件夹',
  folder_name: '文件夹名称',
  create: '创建',
  export_preview: '导出预览',
  combine_segments: '合并段落',
  export_audio_only: '仅当音频正在转录时，您可以导出音频。',
  transcribe_remaining_x_minutes: '转录剩余 {x} 分钟',
  x_minutes_remain_be_transcribed: '剩余 {x} 分钟待转录',
  free_member_limited_transcribe_view: '仅限免费会员查看前 {x} 分钟的转录内容',
  upgrade_plan_to_transcribe: '升级您的方案以转录完整音频',
  only_paid_user_can_use: '仅付费用户可转录剩余分钟',
  converting_to_audio: '正在转换为音频',
}
