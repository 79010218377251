import { createStore } from 'vuex'

export default createStore({
  state: {
    asideVisible: false,
    sidebarVisible: true,
    sidebarUnfoldable: false,
    theme: 'default',
    posDirectMode: true,
    segmentFilter: false,
    segmentReplace: false,
    segmentExport: false,
    audioSummary: false,
    audioMessages: false,
    scrollLock: true,
  },
  mutations: {
    initSegmentSetting(state) {
      state.segmentReplace = false
      state.segmentExport = false
      state.segmentFilter = false
      state.audioSummary = false
      state.audioMessages = false
    },
    toggleSegmentScrollLock(state) {
      state.scrollLock = !state.scrollLock
    },
    toggleAudioSummary(state) {
      state.audioSummary = !state.audioSummary
      state.audioMessages = false
      state.segmentReplace = false
      state.segmentExport = false
      state.segmentFilter = false
    },
    toggleAudioMessages(state) {
      state.audioSummary = false
      state.audioMessages = !state.audioMessages
      state.segmentReplace = false
      state.segmentExport = false
      state.segmentFilter = false
    },
    toggleSegmentFilter(state) {
      state.audioSummary = false
      state.audioMessages = false
      state.segmentReplace = false
      state.segmentExport = false
      state.segmentFilter = !state.segmentFilter
    },
    toggleSegmentReplace(state) {
      state.audioSummary = false
      state.audioMessages = false
      state.segmentFilter = false
      state.segmentExport = false
      state.segmentReplace = !state.segmentReplace
    },
    toggleSegmentExport(state) {
      state.audioSummary = false
      state.audioMessages = false
      state.segmentReplace = false
      state.segmentFilter = false
      state.segmentExport = !state.segmentExport
    },
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    togglePosDirectMode(state) {
      state.posDirectMode = !state.posDirectMode
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
  },
  actions: {},
  modules: {},
})
